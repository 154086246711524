import React, { useState, useContext } from "react";
import {
  makeStyles,
  Modal,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemIcon,
  SvgIcon,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"; // Import CloseIcon
import { UserContext } from "src/context/User";
import { toast } from "react-toastify";

import { SUPPORTED_WALLETS } from "src/connectors";
import SettingsContext from "src/context/SettingsContext";

import {
  handleUnisatConnect,
  handleMetaMaskConnect,
  handleOKXConnect,
} from "src/utils/walletUtils";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.grey[900],
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  walletButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  listItem: {
    border: `1px solid ${theme.palette.grey[800]}`,
    backgroundColor: `#2c2b2b`,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(1),
    color: "white",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[700],
    },
  },
  tabSelected: {
    borderBottom: `2px solid ${theme.palette.grey[800]}`, // Add custom border to selected tab
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const walletOptions = {
  BTC: [
    {
      name: "UniSat",
      icon: "unisat",
      handler: "handleUnisatConnect",
      isDisabled: false,
    },
    {
      name: "OKX Wallet",
      icon: "okx",
      handler: "handleOKXConnect",
      isDisabled: true,
    },
  ],
  EVM: [
    {
      name: "MetaMask",
      icon: "metamask",
      handler: "handleMetaMaskConnect",
      isDisabled: false,
    },
    {
      name: "OKX Wallet",
      icon: "okx",
      handler: "handleOKXConnect",
      isDisabled: true,
    },
  ],
};

const ConnectWalletModal = ({ open, handleClose }) => {
  const user = useContext(UserContext);

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [selectedNetwork, setSelectedNetwork] = useState(0);
  const themeSeeting = useContext(SettingsContext);

  const networks = ["BTC", "EVM"];

  const handleNetworkChange = (event, newValue) => {
    setSelectedNetwork(newValue);
  };

  // Mapping handlers dynamically
  const walletHandlers = {
    handleUnisatConnect: () => handleUnisatConnect(user, handleClose),
    handleMetaMaskConnect: () => handleMetaMaskConnect(user, handleClose),
    handleOKXConnect,
  };

  // Trigger the appropriate handler based on the wallet selected
  const handleWalletClick = (handlerName) => {
    if (walletHandlers[handlerName]) {
      walletHandlers[handlerName](); // Call the handler dynamically
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="wallet-selection-modal-title"
      aria-describedby="wallet-selection-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>

        {/* Network Selection Tabs */}
        <Tabs
          value={selectedNetwork}
          onChange={handleNetworkChange}
          indicatorColor="none"
          textColor="primary"
          className={classes.tabs}
        >
          {networks.map((network) => (
            <Tab
              key={network}
              label={`Connect ${network} Wallet`}
              style={{
                color: "white",
                borderRadius: "0px",
              }}
              classes={{
                selected: classes.tabSelected,
              }}
              disableRipple
            />
          ))}
        </Tabs>

        {/* Wallet Options */}
        <Box>
          <List>
            {walletOptions[networks[selectedNetwork]].map((wallet) => (
              <ListItem
                key={wallet.name}
                className={classes.listItem}
                onClick={() =>
                  !wallet.isDisabled && handleWalletClick(wallet.handler)
                }
                style={{
                  opacity: wallet.isDisabled ? 0.5 : 1,
                  pointerEvents: wallet.isDisabled ? "none" : "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <img
                    src={`/images/wallets/${wallet.icon}.png`}
                    alt={`${wallet.name} icon`}
                    style={{ width: 24, height: 24 }}
                  />
                  <ListItemText primary={wallet.name} />
                </div>
              </ListItem>
            ))}
          </List>
        </Box>
      </div>
    </Modal>
  );
};

export default ConnectWalletModal;
