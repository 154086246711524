// src/utils/walletUtils.js

import axios from "axios";
import { toast } from "react-toastify";
import unisatUtils from "src/utils/unisat";
import Apiconfig from "src/ApiConfig/ApiConfig";

export const handleUnisatConnect = async (user, handleClose) => {
  try {
    const walletAddress = await unisatUtils.getWalletAddress();
    const requestBody = {
      walletAddress,
      walletPlatform: "BTC",
      walletProvider: "UNISAT",
    };

    const res = await axios.post(Apiconfig.connectWallet, requestBody);

    if (res.status === 200) {
      const { message, signature } = await unisatUtils.connectWallet(
        res.data.result.nonce
      );

      const verifyRequest = {
        walletPlatform: "BTC",
        walletAddress: res.data.result.walletAddress,
        walletProvider: "UNISAT",
        signature,
        message,
      };

      const verifyRes = await axios.post(Apiconfig.verifyWallet, verifyRequest);

      if (verifyRes.status === 200) {
        user.autheticateUnisatWallet(verifyRes);
        handleClose();
      }
    } else {
      console.error("Error connecting to UniSat Wallet:", res.data.message);
    }
  } catch (error) {
    console.error("Error connecting to UniSat Wallet:", error);
  }
};

export const handleMetaMaskConnect = async (user, handleClose) => {
  try {
    const walletAddress = await getMetaMaskWalletAddress();
    const requestBody = {
      walletAddress,
      walletPlatform: "EVM",
      walletProvider: "METAMASK",
    };

    const res = await axios.post(Apiconfig.connectWallet, requestBody);

    if (res.status === 200) {
      const { message, signature } = await signMetaMaskMessage(
        res.data.result.nonce
      );

      const verifyRequest = {
        walletPlatform: "EVM",
        walletAddress: res.data.result.walletAddress,
        walletProvider: "METAMASK",
        signature,
        message,
      };

      const verifyRes = await axios.post(Apiconfig.verifyWallet, verifyRequest);

      if (verifyRes.status === 200) {
        user.authenticateMetaMaskWallet(verifyRes.data);
        handleClose();
      }
    } else {
      console.error("Error connecting to MetaMask Wallet:", res.data.message);
    }
  } catch (error) {
    console.error("Error connecting to MetaMask Wallet:", error);
  }
};

export const handleOKXConnect = async () => {
  try {
    toast.info("Coming Soon...");
  } catch (error) {
    console.error("Error connecting to OKX Wallet:", error);
  }
};

export const getMetaMaskWalletAddress = async () => {
  try {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const accounts = await window.ethereum.request({ method: "eth_accounts" });

    if (Array.isArray(accounts)) {
      return accounts[0];
    } else if (typeof accounts === "string") {
      return accounts;
    } else {
      throw new Error("Unexpected response type");
    }
  } catch (error) {
    console.error("Error getting MetaMask wallet address:", error);
    return null;
  }
};

export const signMetaMaskMessage = async (message) => {
  try {
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [message, window.ethereum.selectedAddress],
    });

    return { message, signature };
  } catch (error) {
    console.error("Error signing MetaMask message:", error);
    return null;
  }
};
