export class WebSocketClient {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.pingInterval = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 3000; // Initial delay (3 seconds)
    this.maxReconnectDelay = 30000; // Max delay (30 seconds)
    this.isIntentionalClose = false; // Track if the close is intentional
    this.listeners = {};
    this.clientId = localStorage.getItem("clientId") || null; // Retrieve clientId from localStorage
  }

  connect() {
    this.isIntentionalClose = false; // Reset the flag on every connection attempt

    let urlWithClientId = this.url;
    if (this.clientId) {
      urlWithClientId += `?clientId=${this.clientId}`;
    }
    this.socket = new WebSocket(urlWithClientId);

    this.socket.onopen = () => {
      // Only listen for clientId once during the initial connection
      if (!this.clientId) {
        const handleInitialMessage = (event) => {
          const message = JSON.parse(event.data);

          // Check if the message contains clientId
          if (message.clientId) {
            this.clientId = message.clientId; // Store clientId
            localStorage.setItem("clientId", this.clientId); // Save clientId to localStorage
            this._notifyListeners("clientId", this.clientId); // Notify listeners
            this.socket.removeEventListener("message", handleInitialMessage); // Remove this one-time listener
          }
        };

        // Attach the one-time listener
        this.socket.addEventListener("message", handleInitialMessage);
      }

      this._notifyListeners("open", {});
      this.startPing(); // Start pinging to keep the connection alive
    };

    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      // Handle the pong response from the server
      if (message.type === "pong") {
        return;
      }

      // Handle other messages
      this._notifyListeners("message", message);
    };

    this.socket.onclose = (event) => {
      this._notifyListeners("close", {});

      // Only attempt to reconnect if the close was unintentional
      if (!this.isIntentionalClose) {
        this.reconnect(); // Attempt to reconnect with exponential backoff
      }
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      this._notifyListeners("error", error);
    };
  }

  // Reconnection logic with exponential backoff and jitter
  reconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      const jitter = Math.random() * 1000; // Add jitter to prevent thundering herd problem
      const delay = Math.min(
        this.reconnectDelay * this.reconnectAttempts + jitter,
        this.maxReconnectDelay
      );

      setTimeout(() => this.connect(), delay);
    } else {
      console.error("Max reconnection attempts reached. Unable to reconnect.");
    }
  }

  // Start pinging the server every 30 seconds to keep the connection alive
  startPing() {
    this.pingInterval = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: "ping" }));
      }
    }, 30000); // Ping every 30 seconds
  }

  // Stop pinging when the connection is closed
  stopPing() {
    clearInterval(this.pingInterval);
  }

  // Close the WebSocket connection gracefully
  close() {
    this.isIntentionalClose = true; // Mark the close as intentional
    if (this.socket) {
      this.socket.close(); // Close the WebSocket connection
    }
    this.stopPing(); // Stop pinging the server
  }

  // Send a message through the WebSocket
  send(data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  }

  // Notify listeners for specific events (e.g., open, message, close, error)
  _notifyListeners(event, data) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data));
    }
  }

  // Add a listener for WebSocket events
  addListener(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  // Remove a specific listener for WebSocket events
  removeListener(event, callback) {
    if (!this.listeners[event]) return;
    this.listeners[event] = this.listeners[event].filter(
      (cb) => cb !== callback
    );
  }
}
