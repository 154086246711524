import React, { useState, useContext } from "react";
import { Box, Button } from "@material-ui/core";
import { UserContext } from "src/context/User";
import ConnectWalletModal from "./ConnectWalletModal";

const ConnectWalletButton = () => {
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const user = useContext(UserContext);

  const handleClick = async () => {
    // open wallet Modal
    setWalletModalOpen(true);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        style={{
          whiteSpace: "pre",
          fontWeight: "500",
          color: "white",
          fontSize: "13px",
          marginLeft: "15px",
        }}
        onClick={handleClick}
      >
        Connect Wallet
      </Button>
      <ConnectWalletModal
        open={walletModalOpen}
        handleClose={() => setWalletModalOpen(false)}
      />
    </Box>
  );
};

export default ConnectWalletButton;
