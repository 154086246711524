import React, { createContext, useContext, useEffect, useState } from "react";
import { WebSocketClient } from "src/utils/websocket";
import { toast } from "react-toastify";
import { UserContext } from "./User";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const { isLogin, userData } = useContext(UserContext);
  const [wsClient, setWsClient] = useState(null);
  const [clientId, setClientId] = useState(
    localStorage.getItem("clientId") || null
  );
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (isLogin && userData) {
      const client = new WebSocketClient(process.env.REACT_APP_WEBSOCKET_URL);
      client.connect();

      client.addListener("open", () => {});

      client.addListener("clientId", (msg) => {
        if (msg) {
          setClientId(msg);
          localStorage.setItem("clientId", msg);
        } else {
          toast.error("Error getting Client ID from WebSocket server");
        }
      });

      client.addListener("message", (data) => {
        try {
          const msg = data.msg;
          if (msg !== undefined && msg.generationType === "image") {
            toast.success("Image Generated!");
            updateImages(msg);
          }
          if (msg !== undefined && msg.generationType === "3D") {
            toast.success("3D Model Generated!");
            updateImages(msg);
          }
          setMessages((prev) => [...prev, msg]);
        } catch (error) {
          console.error("Error processing WebSocket message: ", error);
        }
      });

      client.addListener("close", (event) => {
        // console.log(
        //   `WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason}`
        // );
      });

      setWsClient(client);

      return () => {
        if (client) {
          client.close(); // Gracefully close the WebSocket when the component unmounts
        }
      };
    }
  }, [isLogin, userData]);

  return (
    <WebSocketContext.Provider value={{ wsClient, clientId, messages }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Helper functions
const saveModelsArrToLocalStorage = (updatedModels) => {
  localStorage.setItem("models_new", JSON.stringify(updatedModels));
  const event = new Event("modelsUpdated");
  window.dispatchEvent(event);
};

const loadModelsFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("models_new")) || [];
};

const updateImages = (msg) => {
  const models = loadModelsFromLocalStorage();
  const updatedModels = [msg, ...models];
  saveModelsArrToLocalStorage(updatedModels);
};

export const useWebSocket = () => useContext(WebSocketContext);
